/* Media query for mobile devices */
@media screen and (max-width: 800px) {
  .recent .text {
    padding: 15px; /* Reduce padding for items on mobile */
  }
  
  .recent .category {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping for items */
    justify-content: space-between; /* Spread items evenly */
    margin-bottom: 10px; /* Add margin between categories */
  }
  
  .recent .category .box {
    width: calc(50% - 10px); /* Set width for each box (minus margin) */
    margin-bottom: 10px; /* Add margin between boxes */
  }
  
  .recent .button {
    padding: 10px 15px; /* Adjust padding for button on mobile */
  }

  /* Ensure images don't exceed their container width */
  .recent .box img {
    max-width: 100%; /* Ensure images don't exceed their container width */
    height: auto; /* Maintain aspect ratio */
  }
}
