.hero {
  background-image: url("../../../../public/images/baner.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}

.hero .container {
  padding-top: 15%;
}

.hero h1 {
  color: white;
  font-size: 60px;
}

.hero p {
  color: white;
  opacity: 3.0;
  
}

form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}

form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}

form span {
  font-size: 14px;
  color: grey;
}

input::placeholder {
  font-size: 17px;
  color: black;
}

form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

form .box:nth-child(1) {
  border-left: none;
}

form h4 {
  font-weight: 500;
}

/* Media query for smaller screens */
@media screen and (max-width: 800px) {
  form button {
    display: block; /* Ensure the search button is displayed as a block element */
    width: 100%; /* Make the search button full width on mobile */
    margin-top: 10px; /* Add some top margin for spacing */
  }
}
