/* Default styles */
.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}

.featured img {
  width: 65px;
  height: 65px;
  margin: auto;
}

/* Media query for mobile devices */
@media screen and (max-width: 800px) {
  .featured .box {
    width: 100%; /* Display each box as full width on mobile */
    margin-bottom: 20px; /* Add margin between boxes for spacing */
  }
}
/* Media query for mobile devices */
@media screen and (max-width: 800px) {
  .featured img {
    display: block; /* Display each image as a block element on mobile */
    margin: auto; /* Center the images horizontally */
    margin-bottom: 20px; /* Add margin between images for spacing */
  }
}